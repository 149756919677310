<template>
    <div>
        <WaveDownBG>
            <slot name="navigation" />
        </WaveDownBG>

        <div class="c w-s my-xxl pb-xxl">
            <div v-if="typeof article.config_en === 'string'" class="richtext color-secondary leading-m l-up:pr-xl accentlink" v-html="article.config_en" />
        </div>

        <div class="c w-xxl mb-xxl">
            <lazy-component tag="div" class="block--embed-inline">
                <iframe src="https://player.castr.io/live_63fe85c0283311eb93a09574e9086ad8" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </lazy-component>
        </div>
    </div>
</template>

<script>
import WaveDownBG from '@/components/bgs/WaveDownSmall.vue';
import { strings } from 'p5-helpers';

export default {
    name: 'Article',
    components: {
        WaveDownBG,
    },
    data() {
        return {
            article: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => vm.getData());
    },
    beforeRouteUpdate(to, from, next) {
        next();
        this.getData();
    },
    methods: {
        getData() {
            if (window.PRERENDER_INJECTED) {
                const routedata = window.PRERENDER_INJECTED.routeData[this.$route.path];
                if (routedata) {
                    this.processData(routedata);
                }
            } else {
                const url = strings.replaceBrackets(this.$route.meta.article.endpoint, this.$route.params);
                this.$store.dispatch('get', url).then(this.processData);
            }
        },

        processData(r) {
            this.article = r;

            let schemas = [];
            if (this.$route.meta.article.schema) {
                if (typeof this.$route.meta.article.schema === 'function') {
                    schemas = this.$route.meta.article.schema(r, this);
                } else {
                    schemas = this.$route.meta.article.schema;
                }
            }
            this.$store.commit('setMeta', {
                data: {
                    title: r.meta_title_en,
                    description: r.meta_description_en,
                    keywords: (Array.isArray(r.meta_keywords_en) ? r.meta_keywords_en : []).map((it) => it.name).join(', '),
                },
                schemas,
            });
        },
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>

<style>
.block--embed-inline {
    border-radius: var(--radius-m);
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;
    display: block;
    margin: var(--size-xl) 0;
}
.block--embed-inline iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>